import { Fragment, useEffect, useMemo, useState } from 'react';
import ZimAsyncTypeahead from '../../../../shared/ZimAsyncTypeahead/ZimAsyncTypeahead';
import './styles/NavbarCountryLanguage.scss';
import { useCookies } from 'react-cookie';
import { appHelper } from '../../../../helpers/appHelper';
import { translationService } from '../../../../services/translationsService';
import { CountryModel, GeoLocation, LanguageModel } from '../../../../types/dto/MenuModel';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../environments/environment';
import { getData } from '../../../../services/crud';

type Props = {
  countries?: CountryModel[];
  languages?: LanguageModel[];
  geoLocation?: GeoLocation;
  isRoot?: boolean;
};

const NavbarCountryLanguage = ({
  countries = [],
  languages,
  geoLocation,
  isRoot = true
}: Props) => {
  const [cookies, setCookie] = useCookies(['language', 'country', 'langPath', 'countryName']);
  const { t } = useTranslation();
  const translations = {
    country: t('menu.Country'),
    language: t('menu.Language'),
    submit: t('menu.SubmitCountryLanguage')
  };
  const [OnSelectedCountry, setOnSelectedCountry]: any = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage]: any = useState();
  const [isLanguageChanged, setIsLanguageChanged] = useState<boolean>(false);
  const [countryName, setCountryName] = useState<string>(
    cookies?.countryName && cookies?.countryName != ''
      ? cookies?.countryName
      : geoLocation?.countryName
  );

  const cookieDomain = useMemo(() => {
    const domain = appHelper.getDomainWithoutSubdomain().split('/')[0];
    if (domain === 'localhost') {
      return domain;
    }
    return `.${domain}`;
  }, []);

  const filterBy = () => true;

  useEffect(() => {
    initLanguages();
    initCountries();
  }, []);

  const initLanguages = () => {
    languages?.forEach((lang) => {
      if (lang.isoCode === cookies.language) {
        setSelectedLanguage(lang);
      }
    });
  };

  const initCountries = (countryCode = null) => {
    let cookCountry = countryCode ?? cookies.country;
    if (!cookCountry) {
      cookCountry = 'US';
      setCookie('country', 'US', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
      setCookie('countryName', 'U.S.A.', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }

    countries.map(
      (c) =>
        c.countries?.length &&
        c.countries.map((country) => {
          if (country.isoCode === cookCountry) {
            setOnSelectedCountry(country);
            setCookie('countryName', country?.name, {
              path: '/',
              expires: appHelper.cookiesExpiryDate(),
              secure: true,
              domain: cookieDomain
            });
          }
        })
    );
  };

  const handleSearch = (query) => {
    getData(
      `${env.fixed.navbar.getAutocompleteCountries}?query=${query}`,
      'navbarCountryAutocomplete'
    ).then((result) => {
      setOptions(result);
    });
  };

  const onKeepChanges = (e) => {
    e.preventDefault();
    const curPrefix = cookies?.langPath;
    if (OnSelectedCountry && OnSelectedCountry?.length) {
      setCookie('country', OnSelectedCountry[0].key, {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
      initCountries(OnSelectedCountry[0].key);
    }
    if (selectedLanguage && selectedLanguage?.isoCode) {
      setCookie('language', selectedLanguage.isoCode, {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
      setCookie('langPath', selectedLanguage.prefix, {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }
    const path = getLangUrlPath(selectedLanguage?.prefix, curPrefix);
    history.pushState(selectedLanguage?.prefix, document?.title, path === '' ? '/' : path);

    window.location.reload();
  };

  const getLangUrlPath = (prefix: string, curPrefix: string) => {
    const currentRoute = window.location.pathname + window.location.search;
    const newLangPrefix = prefix === '' ? '' : `/${prefix}`;

    if (curPrefix === '') return newLangPrefix + currentRoute;
    return currentRoute.replace(`/${curPrefix}`, newLangPrefix);
  };

  return (
    <div className="navbar-country-language">
      <div className="country">
        <label>{translations.country}</label>
        <ZimAsyncTypeahead
          filterBy={filterBy}
          onChange={(con) => setOnSelectedCountry(con)}
          isLoading={false}
          className="country-autocomplete"
          labelKey="name"
          minLength={1}
          onSearch={handleSearch}
          options={options}
          placeholder={countryName}
          onFocus={() => setCountryName('')}
          onBlur={() => setCountryName(OnSelectedCountry?.name)}
          renderMenuItemChildren={(option: any, props) => (
            <Fragment>
              <span>{option.name}</span>
            </Fragment>
          )}
        />
      </div>
      <div className="language">
        <label>{translations.language}</label>
        <select
          className="language-dropdown"
          onChange={(e) => {
            setSelectedLanguage(languages.find((el) => el.name === e.target.value));
            setIsLanguageChanged(true);
          }}>
          {languages?.map((language) => (
            <option
              key={'language_menu_0_' + language?.name}
              selected={language?.isoCode === cookies.language}
              disabled={language?.isoCode === cookies.language}>
              {language.name}
            </option>
          ))}
        </select>
      </div>
      <button className="btn" onClick={onKeepChanges}>
        {translations.submit}
      </button>
    </div>
  );
};

export default NavbarCountryLanguage;
